import Vue from 'vue'
import * as AxiosLogger from 'axios-logger'

// axios
import axios from 'axios'

const axiosIns = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_URL,
})

const logoutAction = () => {
  localStorage.clear();
  window.location.href = '/pages/login';
}

const setAuthorizationHeader = instance => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });

    instance.interceptors.response.use(
      response => response,
      error => {
        const responseStatus = error.response?.status;

        if (responseStatus === 401) {
          logoutAction();
        }

        return Promise.reject(error);
      }
    );
  }

  return instance;
};


axiosIns.interceptors.request.use(AxiosLogger.requestLogger)

Vue.prototype.$http = setAuthorizationHeader(axiosIns)

export default axiosIns
