<template>
    <div>
        <b-form-file v-model="value" @change="changeEvnt"></b-form-file>
        <div class="preview" v-show="url != null">
            <img v-if="url" :src="url" alt=""/>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            url: null,
        }
    },
    mounted() {
        // console.log(this.value)
    },
    methods: {
        changeEvnt(ev) {
            const file = ev.target.files[0];
            this.url = URL.createObjectURL(file);
            this.$emit('change', ev.target.files)
        }
    }
}
</script>

<style scoped>
    .preview {
        padding: 20px 0;
    }
    .preview img {
        max-width: 100%;
    }
</style>