export default [
  {
    path: '/dashboards',
    name: 'dashboards',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      authRequired: true,
    },
  },
]
