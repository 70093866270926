export default [
  // Link User
  {
    path: '/user/list',
    name: 'user/list',
    component: () => import('@/views/master/users/ListUser.vue'),
    meta: {
      pageTitle: () => 'User',
      breadcrumb: [
        {
          text: 'User Lists',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/user/form',
    name: 'user/form',
    component: () => import('@/views/master/users/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add User',
      breadcrumb: [
        {
          text: 'User Lists',
          to: '/user/list',
        },
        {
          text: 'Add User',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/user/form/:id',
    name: 'user/form/:id',
    component: () => import('@/views/master/users/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Edit User',
      breadcrumb: [
        {
          text: 'User Lists',
          to: '/user/list',
        },
        {
          text: 'Edit User',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  // Route Cabang
  {
    path: '/cabang',
    name: 'cabang',
    component: () => import('@/views/master/branch/Lists.vue'),
    meta: {
      pageTitle: () => 'Cabang List',
      breadcrumb: [
        {
          text: 'Cabang List',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/cabang/form',
    name: 'cabang/form',
    component: () => import('@/views/master/branch/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add Cabang',
      breadcrumb: [
        {
          text: 'Cabang List',
          to: '/cabang',
        },
        {
          text: 'Add Cabang',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/cabang/form/:id',
    name: 'cabang/form/:id',
    component: () => import('@/views/master/branch/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Edit Cabang',
      breadcrumb: [
        {
          text: 'Cabang List',
          to: '/cabang',
        },
        {
          text: 'Edit Cabang',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  // Router Makanan
  {
    path: '/makanan',
    name: 'makanan',
    component: () => import('@/views/master/makanan/ListMakanan.vue'),
    meta: {
      pageTitle: () => 'Makanan List',
      breadcrumb: [
        {
          text: 'Makanan List',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/makanan/form',
    name: 'makanan/form',
    component: () => import('@/views/master/makanan/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add Makanan',
      breadcrumb: [
        {
          text: 'Makanan List',
          to: '/makanan',
        },
        {
          text: 'Add Makanan',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/makanan/form/:id',
    name: 'makanan/form/:id',
    component: () => import('@/views/master/makanan/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Edit Makanan',
      breadcrumb: [
        {
          text: 'Makanan List',
          to: '/makanan',
        },
        {
          text: 'Edit Makanan',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  // Router Paket
  {
    path: '/paket',
    name: 'paket',
    component: () => import('@/views/master/paket/ListPaket.vue'),
    meta: {
      pageTitle: () => 'Paket List',
      breadcrumb: [
        {
          text: 'Paket List',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/form',
    name: 'paket/form',
    component: () => import('@/views/master/paket/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add Paket',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Add Paket',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/form/:id',
    name: 'paket/form/:id',
    component: () => import('@/views/master/paket/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Edit Paket',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Edit Paket',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/detail/form/:id',
    name: 'paket/detail/form/:id',
    component: () => import('@/views/master/paket/ListMenu.vue'),
    meta: {
      pageTitle: () => 'Menu Paket List',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Menu Paket List',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/detail/menu/form/:id',
    name: 'paket/detail/menu/form/:id',
    component: () => import('@/views/master/paket/FormCreateMenuPaket.vue'),
    meta: {
      pageTitle: () => 'Add Menu Paket',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Menu Paket List',
          // to: '/paket/detail/form/:id',
        },
        {
          text: 'Add Menu Paket',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/detail/menu/edit/:id',
    name: 'paket/detail/menu/edit/:id',
    component: () => import('@/views/master/paket/FormEditMenuPaket.vue'),
    meta: {
      pageTitle: () => 'Edit Menu Paket',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Menu Paket List',
          // to: '/paket/detail/form/:id',
        },
        {
          text: 'Edit Menu Paket',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/detailmenu/form/:idpaket/:idmenu',
    name: 'paket/detailmenu/form/:idpaket/:idmenu',
    component: () => import('@/views/master/paket/ListMenuOption.vue'),
    meta: {
      pageTitle: () => 'Menu Option',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Menu Paket List',
          // to: '/paket',
        },
        {
          text: 'Menu Option',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/detail/menuoptional/form/:idpaket/:idmenu',
    name: 'paket/detail/menuoptional/form/:idpaket/:idmenu',
    component: () => import('@/views/master/paket/FormCreateMenuOptional.vue'),
    meta: {
      pageTitle: () => 'Add Menu Optional',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Menu Paket List',
          // to: '/paket',
        },
        {
          text: 'Add Menu Optional',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/paket/detail/menuoptional/edit/:id',
    name: 'paket/detail/menuoptional/edit/:id',
    component: () => import('@/views/master/paket/FormEditMenuOptional.vue'),
    meta: {
      pageTitle: () => 'Edit Menu Optional',
      breadcrumb: [
        {
          text: 'Paket List',
          to: '/paket',
        },
        {
          text: 'Menu Paket List',
          // to: '/paket',
        },
        {
          text: 'Edit Menu Optional',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  // Router Jenis Acara
  {
    path: '/jenisacara',
    name: 'jenisacara',
    component: () => import('@/views/master/jenisacara/ListJenisAcara.vue'),
    meta: {
      pageTitle: () => 'Jenis Acara List',
      breadcrumb: [
        {
          text: 'Jenis Acara List',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/jenisacara/form',
    name: 'jenisacara/form',
    component: () => import('@/views/master/jenisacara/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add Jenis Acara',
      breadcrumb: [
        {
          text: 'Jenis Acara List',
          to: '/jenisacara',
        },
        {
          text: 'Add Jenis Acara',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/jenisacara/form/:id',
    name: 'jenisacara/form/:id',
    component: () => import('@/views/master/jenisacara/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Edit Jenis Acara',
      breadcrumb: [
        {
          text: 'Jenis Acara List',
          to: '/jenisacara',
        },
        {
          text: 'Edit Jenis Acara',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  // Router Event
  {
    path: '/event',
    name: 'event',
    component: () => import('@/views/master/event/ListEvent.vue'),
    meta: {
      pageTitle: () => 'Event List',
      breadcrumb: [
        {
          text: 'Event List',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/event/form',
    name: 'event/form',
    component: () => import('@/views/master/event/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add Event',
      breadcrumb: [
        {
          text: 'Event List',
          to: '/event',
        },
        {
          text: 'Add Event',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/event/form/:id',
    name: 'event/form/:id',
    component: () => import('@/views/master/event/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Edit Event',
      breadcrumb: [
        {
          text: 'Event List',
          to: '/event',
        },
        {
          text: 'Edit Event',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  // Router Info
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/master/info/ListInfo.vue'),
    meta: {
      pageTitle: () => 'Info List',
      breadcrumb: [
        {
          text: 'Info List',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/info/form',
    name: 'info/form',
    component: () => import('@/views/master/info/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add Info',
      breadcrumb: [
        {
          text: 'Info List',
          to: '/info',
        },
        {
          text: 'Add Info',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/info/form/:id',
    name: 'info/form/:id',
    component: () => import('@/views/master/info/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Edit Info',
      breadcrumb: [
        {
          text: 'Info List',
          to: '/info',
        },
        {
          text: 'Edit Info',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  // Router Limit Hour
  {
    path: '/limithour',
    name: 'limithour',
    component: () => import('@/views/master/limithour/LimitHour.vue'),
    meta: {
      pageTitle: () => 'Limit Hour',
      breadcrumb: [
        {
          text: 'Limit Hour',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/limithour/form',
    name: 'limithour/form',
    component: () => import('@/views/master/limithour/FormCreateEdit.vue'),
    meta: {
      pageTitle: () => 'Add Limit Hour',
      breadcrumb: [
        {
          text: ' Limit Hour',
          to: '/limithour',
        },
        {
          text: 'Add  Limit Hour',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
]
