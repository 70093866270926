import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VueFormJsonSchema from 'vue-form-json-schema'
import BFormFilePreview from '@/form-components/BFormFilePreview.vue'
import BFormImage from '@/form-components/BFormImage.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('vue-form-json-schema', VueFormJsonSchema)
Vue.component('b-form-file-preview', BFormFilePreview)
Vue.component('b-form-image', BFormImage)
