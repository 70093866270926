export default [
  {
    path: '/transaksi/submitcustomer',
    name: 'transaksi/submitcustomer',
    component: () => import('@/views/master/transaksi/TransaksiSubmit.vue'),
    meta: {
      pageTitle: () => 'Daftar Submit by Customer',
      breadcrumb: [
        {
          text: 'Daftar Submit by Customer',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/transaksi/submitcustomer/form/:id',
    name: 'transaksi/submitcustomer/form',
    component: () => import('@/views/master/transaksi/DetailTransaksi.vue'),
    meta: {
      pageTitle: () => 'Detail Transaksi',
      breadcrumb: [
        {
          text: 'Daftar Submit by Customer',
          to: '/transaksi/submitcustomer',
        },
        {
          text: 'Detail Transaksi',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  {
    path: '/transaksi/orderconfirm',
    name: 'transaksi/orderconfirm',
    component: () => import('@/views/master/transaksi/TransaksiConfirm.vue'),
    meta: {
      pageTitle: () => 'Daftar Order Confirm',
      breadcrumb: [
        {
          text: 'Daftar Order Conform Tanggal Acara Besok',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/transaksi/orderconfirm/form/:id',
    name: 'transaksi/orderconfirm/form',
    component: () => import('@/views/master/transaksi/DetailTransaksi.vue'),
    meta: {
      pageTitle: () => 'Detail Transaksi',
      breadcrumb: [
        {
          text: 'Daftar Order Conform Tanggal Acara Besok',
          to: '/transaksi/orderconfirm',
        },
        {
          text: 'Detail Transaksi',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  {
    path: '/transaksi/allorder',
    name: 'transaksi/allorder',
    component: () => import('@/views/master/transaksi/TransaksiAll.vue'),
    meta: {
      pageTitle: () => 'Daftar All Order',
      breadcrumb: [
        {
          text: 'Daftar All Order',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: '/transaksi/allorder/form/:id',
    name: 'transaksi/allorder/form',
    component: () => import('@/views/master/transaksi/DetailTransaksi.vue'),
    meta: {
      pageTitle: () => 'Detail Transaksi',
      breadcrumb: [
        {
          text: 'Daftar All Order',
          to: '/transaksi/allorder',
        },
        {
          text: 'Detail Transaksi',
          active: true,
        },
      ],
      authRequired: true,
    },
  },

  {
    path: '/transaksi/submitcustomer/form/add/:id',
    name: 'transaksi/submitcustomer/form/add/:id',
    component: () => import('@/views/master/transaksi/AddTransaksi.vue'),
    meta: {
      pageTitle: () => 'Add Paket',
      breadcrumb: [
        {
          text: 'Daftar Submit by Customer',
          to: '/transaksi/submitcustomer',
        },
        {
          text: 'Detail Transaksi',
        },
        {
          text: 'Add Paket',
          active: true,
        },
      ],
      authRequired: true,
    },
  },
]
