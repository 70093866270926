import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import transaksi from './routes/transaksi'
import master from './routes/master'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboards' } },
    ...dashboard,
    ...pages,
    ...transaksi,
    ...master,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // console.log(`USER_LOGGED_IN => ${isLoggedIn}`)

  if (to.name === 'logout') {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    localStorage.removeItem('userData')

    return next({ name: 'auth-login' })
  }

  if (to.meta.authRequired) {
    if (!isLoggedIn) return next({ name: 'auth-login' })
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
