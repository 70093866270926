<template>
    <div>
        <div class="preview">
            <img v-if="value" :src="value" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    mounted() {
        // console.log(this.value)
    },
}
</script>

<style scoped>
    .preview {
        padding-bottom: 20px;
    }
    .preview img {
        max-width: 100%;
    }
</style>